import { FC } from "react";
import "./AnimatedText.css";

export const SearchingText: FC<{ active: boolean }> = ({ active }) => {
  return active ? (
    <h2 className="animationContainer">
      <span>I'm searching for podcasts related to your question.</span>
    </h2>
  ) : null;
};
