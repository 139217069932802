import { useEffect, useState } from "react";
import { AudioInfo } from "../components/SourcePlayer/SourcePlayer";
import { podBotServiceUrl } from "../constants";
import { FirebaseService } from "../services/firebase";
import { io } from "socket.io-client";
import { QuestionResponse } from "../models/podbot";

export default function usePMBot(
  uid: string | null | undefined,
  podcastId: string,
  service: FirebaseService,
  setErrorText: React.Dispatch<React.SetStateAction<string>>
) {
  const [searchText, setSearchText] = useState("");
  const [audioInfos, setAudioInfos] = useState<Array<AudioInfo>>([]);
  const [responseText, setResponseText] = useState("");

  const [questionsAsked, setQuestionsAsked] = useState<number>(0);

  const getQuestionsAsked = async () => {
    const token = await service.getToken();
    const response = await fetch(
      `${podBotServiceUrl}/api/user/getQuestionsAsked`,
      {
        method: "POST",
        body: JSON.stringify({ id: podcastId }),
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    if (data.status === "OK") {
      setQuestionsAsked(data.num || 0);
    }
  };

  useEffect(() => {
    getQuestionsAsked();
  }, []);

  // loading for sources
  const [sourceLoading, setIsLoading] = useState(false);
  // loading for answer
  const [answerLoading, setAnswerLoading] = useState(false);
  // signal that answer is loading
  const [isWriting, setIsWriting] = useState(false);

  // const [questionsAsked, setQuestionsAsked] = useState<number | null>(null);

  useEffect(() => {
    if (!uid) return;
    /*
    const unsubscribe = service.onQuestionsAsked(uid, (count: number) => {
      //console.log("questions_asked", count);
      setQuestionsAsked(count);
    });
    return unsubscribe;
    */
  }, [service, uid]);

  // Function to send rating
  const _sendRating = async (
    question: string,
    response: string,
    episode: string,
    rating: number,
    reason: string
  ) => {
    //const token = await service.getToken();
    /*
    await fetch(`${serverHost}/api/rating`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question,
        response,
        episode,
        rating,
        reason,
        podcast_id: podcatId,
      }),
    });
    */
  };

  const sendRating = async (rating: number, reason: string) => {
    await _sendRating(searchText, responseText, "episode", rating, reason);
  };

  const sendFeedback = async (feedback: string) => {
    //const token = await service.getToken();
    /*
    await fetch(`${serverHost}/api/feedback`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ reason: feedback, podcast_id: podcatId }),
    });
    */
  };

  const startSearch = async () => {
    if (!uid || !searchText) return;
    if (sourceLoading || answerLoading || isWriting) {
      return;
    }
    service.logEvent("start_search");
    service.incQuestionsAsked(uid);
    setErrorText("");
    setAudioInfos([]);
    setResponseText("");
    await fetchResponse(searchText);
    getQuestionsAsked();
  };

  const askQuestion = async (question: string) => {
    if (!uid || sourceLoading || answerLoading || isWriting) {
      return;
    }
    service.incQuestionsAsked(uid);
    setSearchText(question);
    setErrorText("");
    setAudioInfos([]);
    setResponseText("");
    await fetchResponse(question);
    getQuestionsAsked();
  };

  const randomQuestion = async (randomQuestions: string[]) => {
    if (!uid || sourceLoading || answerLoading || isWriting) {
      return;
    }
    if (randomQuestions.filter((q) => q && q.toLowerCase()).length === 0) {
      return;
    }
    service.logEvent("random_question");
    service.incQuestionsAsked(uid);
    const question =
      randomQuestions[Math.floor(Math.random() * randomQuestions.length)];
    setSearchText(question);
    setErrorText("");
    setAudioInfos([]);
    setResponseText("");
    await fetchResponse(question);
    getQuestionsAsked();
  };

  const fetchResponse = async (question: string) => {
    // Set loading to false when data stream is done
    setIsLoading(true);
    let allAnswertext = "";

    const token = await service.getToken();

    const socket = io(podBotServiceUrl);

    socket.on("connect", () => {
      console.log("socket connect");
      socket.emit("question", {
        podcastId: podcastId,
        token: token,
        question: question,
      });
    });

    socket.on("answer", (data) => {
      const answer: QuestionResponse = data as QuestionResponse;
      if (answer.answerAudios) {
        const answerAudios = answer.answerAudios;
        const audioTranscriptions = answerAudios.audioTranscriptions;
        const newAudios: AudioInfo[] = [];
        for (let i = 0; i < audioTranscriptions.length; i++) {
          let audioUrl = audioTranscriptions[i]?.audio ?? "";
          let audioNA = false;
          if (audioUrl === "N/A") {
            audioNA = true;
            audioUrl = "";
          }
          let text = ""; //audioTranscriptions[i]?.text ?? "";
          let transcriptionUrl = audioTranscriptions[i]?.transcription ?? "";
          if (audioNA) {
            text = transcriptionUrl;
            transcriptionUrl = "";
          }

          let startTime = audioTranscriptions[i]?.start ?? "";
          if (audioNA && startTime === "N/A") {
            startTime = "";
          }

          const title = audioTranscriptions[i]?.title ?? "";
          const episode_date = audioTranscriptions[i]?.episodeDate ?? "";
          newAudios.push({
            id: `${i + 1}-${audioTranscriptions[i]?.audioId ?? ""}`,
            num: i + 1,
            title: title,
            audioUrl: audioUrl,
            text: text,
            transcriptionUrl: transcriptionUrl,
            startTime: startTime,
            date: episode_date,
            speakers: audioTranscriptions[i].speakers,
          });
        }

        setAudioInfos(newAudios);
        setIsLoading(false);
        setAnswerLoading(true);
      } else if (answer.answerText) {
        setIsWriting(true);
        const answerText = answer.answerText.text;
        allAnswertext = allAnswertext + answerText;
        setResponseText(allAnswertext);
      }
    });

    socket.on("error", function (err) {
      //setErrorText(err);
      console.error(err);
      setErrorText(err.details || "Request Error");
    });

    socket.on("exception", function (err) {
      console.log("event", err);
      setErrorText(err.details || "Request Error");
    });

    socket.on("disconnect", function () {
      setIsLoading(false);
      setAnswerLoading(false);
      setIsWriting(false);
      getQuestionsAsked();
      console.log("Disconnected");
    });
  };

  return {
    searchText,
    setSearchText,
    startSearch,
    sendRating,
    sendFeedback,
    audioInfos,
    responseText,
    askQuestion,
    randomQuestion,
    answerLoading,
    sourceLoading,
    questionsAsked,
    isWriting,
  };
}
