import { FC, ReactNode, createContext, useContext, useState } from "react";

export const defaultColor = "#6f29f1";

type ColorsMap = {
  [id: string]: string;
};

export interface ThemeContextValue {
  setColor: (color: string) => void;
  getColor: () => string;
}

export const ThemeContext = createContext<ThemeContextValue | undefined>(
  undefined
);

type Props = {
  children?: ReactNode;
};

export const ThemeContextProvider: FC<Props> = ({ children }) => {
  const [colors, setColors] = useState<ColorsMap | null>(null);

  const getColor = () => {
    const newColor = (colors || {})[window.location.pathname] || defaultColor;
    document.body.style.backgroundColor = newColor;
    return newColor;
  };

  const setColor = (color: string) => {
    const oldColors = colors || {};
    setColors({ ...oldColors, ...{ [window.location.pathname]: color } });
  };

  return (
    <ThemeContext.Provider value={{ setColor: setColor, getColor: getColor }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const themeContext = useContext(ThemeContext);
  if (themeContext === undefined) {
    throw new Error("useThemeContext must be inside a ThemeContextProvider");
  }
  return themeContext;
};
