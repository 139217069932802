import { FC } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import "./App.css";
import { Podcast } from "./components/Podcast";
import { service } from "./services/firebase";
import EditPodcast from "./components/EditPodcast/EditPodcast";
import useAdminPodcast from "./hooks/useAdminPodcast";
import { ThemeContextProvider } from "./context/ThemeContext";
import { Layout } from "./layout/layout";
import useDetectPodcast from "./hooks/useDetectPodcast";
import Subscribe from "./components/Subscribe";
import useUserPodcast from "./hooks/useUserPodcast";
import { AdminPodcasts } from "./components/Podcasts";
import useDetectSuperHost from "./hooks/useDetectHost";

function App() {
  const { superHost } = useDetectSuperHost();
  return (
    <ThemeContextProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                index
                element={superHost ? <SuperAdmin /> : <ViewPodcast />}
              />
              <Route path="subscribe">
                <Route
                  index
                  element={superHost ? <SubscribePodcast /> : <NoMatch />}
                />
              </Route>
              <Route path="admin">
                <Route
                  index
                  element={superHost ? <AdminPodcasts /> : <AdminEditPodcast />}
                />
                <Route path=":podcastId">
                  <Route
                    index
                    element={
                      superHost ? <SuperAdminEditPodcast /> : <NoMatch />
                    }
                  />
                </Route>
              </Route>
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </Router>
      </div>
    </ThemeContextProvider>
  );
}

const SubscribePodcast: FC = () => {
  const { podcastId } = useDetectPodcast();
  const { podcast } = useUserPodcast(podcastId, service, () => {});
  if (!podcast) return null;
  return <Subscribe podcast={podcast} />;
};

const SuperAdmin: FC = () => {
  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <Link style={{ padding: 30 }} to={"/admin"}>
        SUPERADMIN
      </Link>
    </div>
  );
};

const AdminEditPodcast: FC = () => {
  const { podcastId } = useDetectPodcast();
  const { podcast, updatePodcast, uploadLogo, audios } = useAdminPodcast(
    podcastId,
    service,
    () => {}
  );
  if (!podcast) return <div style={{ height: "100vh" }}></div>;
  return (
    <EditPodcast
      podcast={podcast}
      uploadLogo={uploadLogo}
      onUpdatePodcast={updatePodcast}
      audios={audios || []}
    />
  );
};

const SuperAdminEditPodcast: FC = () => {
  let { podcastId } = useParams();
  const { podcast, updatePodcast, uploadLogo, audios } = useAdminPodcast(
    podcastId,
    service,
    () => {}
  );
  if (!podcast) return <div style={{ height: "100vh" }}></div>;
  return (
    <EditPodcast
      podcast={podcast}
      uploadLogo={uploadLogo}
      onUpdatePodcast={updatePodcast}
      audios={audios || []}
    />
  );
};

const ViewPodcast: FC = () => {
  const { podcastId } = useDetectPodcast();
  return <Podcast id={podcastId ?? ""} />;
};

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
