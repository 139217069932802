import { FC } from "react";
import styles from "./ErrorText.module.css";

export const ErrorText: FC<{ text: string }> = ({ text }) => {
  return text ? (
    <div className={styles.errorContainer}>
      <p>{text}</p>
    </div>
  ) : null;
};
