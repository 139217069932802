import { FC } from "react";
import styles from "./PodcastImg.module.css";
import { iconsPrefix } from "../../../constants";

interface PropTypes {
  picURL: string;
  loading: boolean;
  onClick: () => void;
}
const PodcastImg: FC<PropTypes> = ({ picURL, onClick, loading }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={styles.change_img} onClick={onClick}>
        <img src={`${picURL}`} alt="Album_cover" width={159} height={159} />
        {loading ? (
          <div style={{ color: "white", fontWeight: "bold" }}>LOADING...</div>
        ) : (
          <img src={`${iconsPrefix}icons/camera.svg`} alt="camera" />
        )}
      </div>
    </div>
  );
};
export default PodcastImg;
