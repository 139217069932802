import styles from "./Footer.module.css";

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <a href="/about" className={styles.footerItem + " " + styles.footerAbout}>
        About
      </a>
      <a href="/privacy" className={styles.footerItem}>
        Privacy Policy
      </a>
      <a
        href="mailto:steve@sunaolab.com"
        className={styles.footerItem + " " + styles.contact}
      >
        Contact
      </a>
      <a href="/terms" className={styles.footerItem}>
        Terms of Service
      </a>
    </div>
  );
};
