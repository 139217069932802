const isLocal = false;
export const port = 8080;
export const podBotServiceDomain = "localhost:" + port;
export const podBotServiceUrl = isLocal
  ? "http://" + podBotServiceDomain
  : "https://podbot-server-evtnyd55nq-uc.a.run.app";

export const serverHost = isLocal
  ? "http://localhost:8080"
  : //: "https://app-evtnyd55nq-uc.a.run.app";
    "https://newapp-evtnyd55nq-uc.a.run.app";
export const iconsPrefix = "/";
