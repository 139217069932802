import { FC, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { service } from "../services/firebase";
import { Outlet, useLocation } from "react-router-dom";
import { Login } from "../components/Login/Login";
import { useThemeContext } from "../context/ThemeContext";
import { podBotServiceUrl } from "../constants";
import { DbPodcast } from "../models/podcast.db";
import useDetectPodcast from "../hooks/useDetectPodcast";

export const Layout: FC = () => {
  const pmAuth = useAuth(service, () => {});
  const email = pmAuth.user?.email;
  const theme = useThemeContext();
  const col = theme.getColor();
  const [backgroundColor, setBackgroundColor] = useState<string>(col);
  const location = useLocation();
  const { podcastId } = useDetectPodcast();

  useEffect(() => {
    const query = async () => {
      if (!podcastId) return;
      const response = await fetch(
        `${podBotServiceUrl}/api/user/getPodcastById`,
        {
          method: "POST",
          body: JSON.stringify({ id: podcastId }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (data.status === "OK") {
        const newPodcast = data.podcast as DbPodcast;
        if (newPodcast && newPodcast.theme.backgroundColor) {
          theme.setColor(newPodcast.theme.backgroundColor);
        }
      }
    };
    query();
  }, [podcastId]);
  useEffect(() => {
    const newColor = theme.getColor();
    setBackgroundColor(newColor);
  }, [location, col, theme]);

  if (!pmAuth.uid) {
    if (pmAuth.uid === null) {
      return (
        <div className="main_container">
          <div style={{ height: 50 }} />
          <Login
            text={pmAuth.email}
            onTextChanged={pmAuth.setEmail}
            onSearchClicked={pmAuth.startAuth}
            sent={pmAuth.mailSent}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: 50,
          backgroundColor: backgroundColor,
          zIndex: 2,
          borderBottom: 1,
          borderBottomColor: "white",
          borderBottomStyle: "solid",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",
            padding: "1em",
            margin: 0,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>({email})</div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 20,
            }}
          >
            <div
              style={{ cursor: "pointer", marginRight: 30 }}
              onClick={() => {
                pmAuth.signOut();
              }}
            >
              LOGOUT
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 50 }}>
        <div style={{ width: "100vw", overflowY: "scroll" }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
