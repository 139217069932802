import { FC } from "react";
import "./AnimatedText.css";

export const SupportUsText: FC<{ active: boolean }> = ({ active }) => {
  return null;
  return active ? (
    <h2 className="animationContainer">
      <span>
        These answers are made possible by producers and reporters at NPR.
        Support us!{" "}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.npr.org/stations/"
        >
          Click here
        </a>
        , and give what you can!
      </span>
    </h2>
  ) : null;
};
