import { FC } from "react";
import styles from "./EditNav.module.css";
import { useNavigate } from "react-router-dom";

interface PropTypes {
  podcastId: string;
  picURL: string;
  isPreview: boolean;
  isAudios: boolean;
  onPreviewClick: () => void;
  onAudiosViewClick: () => void;
}

const EditNav: FC<PropTypes> = ({
  podcastId,
  picURL,
  isPreview,
  isAudios,
  onPreviewClick,
  onAudiosViewClick,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.edit_podcast_nav}>
      <div className={styles.edit_podcast_back} onClick={() => navigate(-1)}>
        <img src={`${picURL}`} alt="Album_cover" />
      </div>
      <div>
        <div
          onClick={onPreviewClick}
          className={styles.message_edit_color}
          style={{ marginTop: 20, cursor: "pointer" }}
        >
          {isPreview ? "Hide Draft" : "View Draft"}
        </div>

        <div
          onClick={onAudiosViewClick}
          className={styles.message_edit_color}
          style={{ marginTop: 20, cursor: "pointer" }}
        >
          {isAudios ? "Hide Audios" : "View Audios"}
        </div>
      </div>
    </div>
  );
};
export default EditNav;
