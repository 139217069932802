import { FC, useEffect, useState } from "react";
import styles from "./Answer.module.css";

interface PropTypes {
  text: string;
}

const pmBotFontSizeKey = "pmBotFontSizeKey";
const pmBotFontColorKey = "pmBotFontColorKey";

export const Answer: FC<PropTypes> = ({ text }) => {
  const [fontSize, setFontSize] = useState(18);
  const [color, setColor] = useState("#000000");

  useEffect(() => {
    const sizeStr = window.localStorage.getItem(pmBotFontSizeKey);
    const parsed = parseInt(sizeStr || "");
    setFontSize(parsed ? parsed : 18);
  }, []);

  useEffect(() => {
    const colorStr = window.localStorage.getItem(pmBotFontColorKey) || "";
    if (/^#[0-9A-F]{6}$/i.test(colorStr)) {
      setColor(colorStr);
    } else {
      setColor("#000000");
    }
  }, []);

  return text === "" ? null : (
    <div>
      <div className={styles.scale}>
        <div
          className={styles.scaleMore}
          onClick={() => {
            const newFonSize = Math.min(fontSize + 1, 40);
            setFontSize(newFonSize);
            window.localStorage.setItem(pmBotFontSizeKey, `${newFonSize}`);
          }}
        >
          A+
        </div>
        <div
          className={styles.scaleLess}
          onClick={() => {
            const newFonSize = Math.max(fontSize - 1, 12);
            setFontSize(newFonSize);
            window.localStorage.setItem(pmBotFontSizeKey, `${newFonSize}`);
          }}
        >
          A-
        </div>
        <div
          className={styles.circleColor}
          style={{
            display: "none",
            backgroundImage: "url(/icons/circle_color.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <input
            type="color"
            onChange={(e) => {
              console.log(e.target.value);
              window.localStorage.setItem(pmBotFontColorKey, e.target.value);
              setColor(e.target.value);
            }}
          ></input>
        </div>
      </div>
      <div className={styles.answer}>
        <div
          className={styles.answerText}
          style={{ fontSize: fontSize, color: color }}
        >
          {text}
        </div>
      </div>
    </div>
  );
};
