import { FC } from "react";
import styles from "./TextInput.module.css";

interface PropTypes {
  text: string;
  placeholder: string;
  onTextChanged: (text: string) => void;
}
const TextInput: FC<PropTypes> = ({ text, placeholder, onTextChanged }) => {
  return (
    <div className={styles.add_name}>
      <input
        className={styles.input_text}
        type="text"
        placeholder={placeholder}
        value={text}
        onChange={(event) => {
          onTextChanged(event.target.value);
        }}
      />
    </div>
  );
};
export default TextInput;
