//1f29b97f-33b8-4857-8a58-4fb9ac670dda mediativestory
//"b2784340-8f34-4a5b-802f-cf94a113509d" pivot

const podcastsMap: { [host: string]: string } = {
  //"localhost:3000": "7b9c6ab4-e7e9-4c6c-8126-73fe6a7b7a9c",
  "meditativestory.podbot.org": "1f29b97f-33b8-4857-8a58-4fb9ac670dda",
  "pivot.podbot.org": "b2784340-8f34-4a5b-802f-cf94a113509d",
  "newsroomrobots.podbot.org": "7b9c6ab4-e7e9-4c6c-8126-73fe6a7b7a9c",
};

export default function useDetectPodcast() {
  //alert(window.location.host);
  const host = window.location.host;
  return { podcastId: podcastsMap[host] };
}
