import { useEffect, useState } from "react";
import { FirebaseService } from "../services/firebase";
import { podBotServiceUrl } from "../constants";
import { DbPodcast } from "../models/podcast.db";
import { useThemeContext } from "../context/ThemeContext";

export default function useUserPodcast(
  podcastId: string | null | undefined,
  service: FirebaseService,
  setErrorText: React.Dispatch<React.SetStateAction<string>>
) {
  const [podcast, setPodcast] = useState<DbPodcast | null>(null);
  const theme = useThemeContext();

  useEffect(() => {
    const query = async () => {
      if (!podcastId) return;
      const token = await service.getToken();
      const response = await fetch(
        `${podBotServiceUrl}/api/user/getPodcastById`,
        {
          method: "POST",
          body: JSON.stringify({ id: podcastId }),
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (data.status === "OK") {
        const newPodcast = data.podcast as DbPodcast;
        if (newPodcast) {
          setPodcast(newPodcast);
        }
      }
    };
    query();
  }, [podcastId, service]);

  useEffect(() => {
    if (podcast) {
      const backgroundColor = podcast.theme.backgroundColor;
      theme.setColor(backgroundColor);
    }
  }, [podcast]);

  return {
    podcast,
  };
}
