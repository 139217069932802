import { useEffect, useState } from "react";
import { FirebaseService } from "../services/firebase";
import { User } from "firebase/auth";

export default function useAuth(
  service: FirebaseService,
  setErrorText: React.Dispatch<React.SetStateAction<string>>
) {
  const [uid, setUid] = useState<undefined | string | null>(undefined);
  const [user, setUser] = useState<undefined | User | null>(undefined);
  const [email, setEmail] = useState<string>("");
  const [mailSent, setMailSent] = useState<boolean>(false);
  useEffect(() => {
    service.onAuthStateChanged((user) => {
      setUser(user ?? null);
      setUid(user?.uid ?? null);
    });
  }, [service]);
  useEffect(() => {
    const fn = async () => {
      try {
        const uid = await service.signInWithEmailLink();
        if (uid) {
          //@ts-ignore
          window.location = window.location.pathname;
        }
      } catch (e) {
        const error = e as { message: string };
        console.log(error);
        setErrorText(error.message);
      }
    };
    fn();
  }, [service, setErrorText]);

  const startAuth = () => {
    if (!email) return;
    try {
      service.sendSigninLinkToEmail(email);
      setMailSent(true);
    } catch (e) {
      const error = e as { message: string };
      setErrorText(error.message);
    }
  };

  const signOut = () => {
    service.signOut();
  };

  return { uid, user, email, mailSent, setEmail, startAuth, signOut };
}
