import "../../App.css";
import Search from "../Search/Search";
import Sources from "../Sources/Sources";
import { Answer } from "../Answer";
import RandomQuestion from "../RandomQuestion/RandomQuestion";
import { SearchingText } from "../Loading/SearchingText";
import { SupportUsText } from "../Loading/SupportUsText";
import { ErrorText } from "../ErrorText";
import { EventEmitter } from "events";
import usePMBot from "../../hooks/usePMBot";
import { iconsPrefix } from "../../constants";
import { Ratings } from "../Ratings/Ratings";
import { service } from "../../services/firebase";
import useAuth from "../../hooks/useAuth";
import { FC, useState } from "react";
import { Footer } from "../Footer";
import { Loading } from "../Loading";
import QuestionsGroup from "../QuestionsGroup";
import { DbPodcast } from "../../models/podcast.db";
import useUserPodcast from "../../hooks/useUserPodcast";
import useCustomerSubscription from "../../hooks/useCustomerSubbscription";
import { useNavigate } from "react-router-dom";

interface PropTypes {
  id: string;
  podcast?: DbPodcast;
}

export const Podcast: FC<PropTypes> = ({ id, podcast: propPodcast }) => {
  const navigate = useNavigate();
  const podcastId = id;
  const [errorText, setErrorText] = useState("");
  const pmAuth = useAuth(service, setErrorText);
  const { podcast: loadedPodcast } = useUserPodcast(
    podcastId,
    service,
    setErrorText
  );
  const { subscription, loading } = useCustomerSubscription(
    pmAuth.uid || "",
    podcastId,
    service,
    () => {}
  );
  const pmBot = usePMBot(pmAuth.uid, podcastId, service, setErrorText);
  const podcast = propPodcast ? propPodcast : loadedPodcast;
  //const abTest = useABText(pmAuth.uid, service, setErrorText);

  const emitter = new EventEmitter();
  return (
    <div className="App">
      <div className="main_container">
        <div className="title">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={podcast?.imageUrl}
              alt=""
              style={{
                width: 100,
                height: 100,
                marginRight: 20,
                borderRadius: 16,
              }}
            />
            <div style={{ fontSize: 50, textAlign: "center" }}>
              {podcast?.name ?? "..."}
            </div>

            {subscription?.subscribed === true ? null : (
              <div
                style={{
                  paddingTop: 10,
                  cursor: "pointer",
                  display:
                    podcastId === "7b9c6ab4-e7e9-4c6c-8126-73fe6a7b7a9c"
                      ? "block"
                      : "none",
                }}
                onClick={() => {
                  if (loading) return;
                  navigate("/subscribe");
                }}
              >
                {loading
                  ? "..."
                  : `You've used ${pmBot.questionsAsked} of 3 free questions, SUBSCRIBE for unlimited access.`}
              </div>
            )}
          </div>

          <img
            src={`${iconsPrefix}icons/PLANET_MONEY_BOT.svg`}
            alt="PLANET_MONEY_BOT"
            style={{ width: "100%", height: "auto", display: "none" }}
          />
        </div>

        <>
          {!(pmBot.sourceLoading || pmBot.answerLoading) ? (
            <QuestionsGroup
              randomQuestions={podcast?.questions || []}
              onClick={pmBot.askQuestion}
            />
          ) : null}
          <Search
            text={pmBot.searchText}
            onTextChanged={pmBot.setSearchText}
            onSearchClicked={pmBot.startSearch}
          />
          <RandomQuestion
            onClick={() => {
              pmBot.randomQuestion(podcast?.questions || []);
            }}
          />
          <ErrorText text={errorText} />
          <SearchingText active={pmBot.sourceLoading} />
          <Loading active={pmBot.sourceLoading || pmBot.answerLoading} />
          <SupportUsText active={pmBot.answerLoading} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              //abTest.abColumn === 1 ? "column-reverse" : "column",
            }}
          >
            <Answer text={pmBot.responseText} />
            <Sources audioInfos={pmBot.audioInfos} emitter={emitter} />
          </div>
          {true ? null : (
            <Ratings
              sendRating={pmBot.sendRating}
              sendFeedback={pmBot.sendFeedback}
              display={
                pmBot.responseText.length > 0 &&
                !(pmBot.answerLoading || pmBot.sourceLoading)
              }
            />
          )}
        </>
      </div>
      <Footer />
    </div>
  );
};

export default Podcast;
