import { FC } from "react";

const RandomQuestion: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div className="question_title" onClick={onClick}>
      Random Question
    </div>
  );
};
export default RandomQuestion;
