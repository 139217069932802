import { FC } from "react";
import styles from "./Pagination.module.css";

interface PropTypes {
  items: number;
  active: number;
}
export const Pagination: FC<PropTypes> = ({ items, active }) => {
  const arr = Array.from(new Array(items).keys());
  return (
    <div className={styles.pagination}>
      {arr.map((itm, index) => {
        const className = [styles.circle, index === active ? styles.active : ""]
          .filter((val) => val)
          .join(" ");
        return <div key={itm} className={className}></div>;
      })}
    </div>
  );
};
