import { FC } from "react";
import styles from "./Loading.module.css";

export const Loading: FC<{ active: boolean }> = ({ active }) => {
  return active ? (
    <div className={styles.circleAnim}>
      <div className={styles.circle1}>
        <div className={styles.circle2}>
          <div className={styles.circle3}></div>
        </div>
      </div>
    </div>
  ) : null;
};
