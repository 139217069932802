import { FC } from "react";
import { iconsPrefix } from "../../constants";

interface PropTypes {
  text: string;
  onTextChanged: (text: string) => void;
  onSearchClicked: () => void;
}

const Search: FC<PropTypes> = ({ text, onTextChanged, onSearchClicked }) => {
  return (
    <div className="search">
      <input
        className="search_input"
        type="text"
        placeholder="Enter a question"
        value={text}
        onChange={(event) => {
          onTextChanged(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onSearchClicked();
          }
        }}
      />
      <div className="search_button" onClick={onSearchClicked}>
        <img src={`${iconsPrefix}icons/search.svg`} alt="search" />
      </div>
    </div>
  );
};
export default Search;
