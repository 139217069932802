import { useEffect, useState } from "react";
import { FirebaseService } from "../services/firebase";
import { podBotServiceUrl } from "../constants";
import { DbPodcast } from "../models/podcast.db";

export default function useAdminPodcasts(
  userUid: string | null,
  service: FirebaseService
) {
  const [podcasts, setPodcasts] = useState<any[]>([]);
  useEffect(() => {
    const query = async () => {
      if (!userUid) return;
      const token = await service.getToken();
      const response = await fetch(
        `${podBotServiceUrl}/api/admin/getPodcasts`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (data.status === "OK") {
        const newPodcasts: DbPodcast[] = data.podcasts ?? [];
        if (newPodcasts) {
          newPodcasts.sort((a, b) => b.createdAt - a.createdAt);
          setPodcasts(newPodcasts);
        }
      }
    };
    query();
  }, [service, userUid]);

  const createPodcast = async (url: string) => {
    if (!userUid) return;
    const token = await service.getToken();
    const response = await fetch(
      `${podBotServiceUrl}/api/admin/createPodcast`,
      {
        method: "POST",
        body: JSON.stringify({ url: url }),
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    if (data.status === "OK") {
      const newPodcast = data.podcast;
      if (newPodcast) {
        setPodcasts([newPodcast, ...podcasts]);
        alert("Podcast created. Processing...");
      }
    } else {
      if (data.status === "ERROR" && data.description) {
        alert("ERROR:" + data.description);
      } else {
        alert("Podcast creation errors");
      }
    }
  };

  const deletePodcast = async (id: string) => {
    if (!userUid) return;
    const token = await service.getToken();
    const response = await fetch(
      `${podBotServiceUrl}/api/admin/deletePodcast`,
      {
        method: "POST",
        body: JSON.stringify({ id: id }),
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    if (data.status === "OK") {
      setPodcasts(podcasts.filter((p) => p.id !== id));
    }
    return data.status;
  };

  return { podcasts, createPodcast, deletePodcast };
}
