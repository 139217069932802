import { FC } from "react";
import useWindowSize from "../../hooks/useWindowSize";

type Props = {
  randomQuestions: string[];
  onClick: (q: string) => void;
};
const QuestionsGroup: FC<Props> = ({ randomQuestions: rq, onClick }) => {
  const randomQuestions = rq.filter((q) => q.trim());
  const windowSize = useWindowSize();
  const columns = windowSize.width > 600 ? 2 : 1;
  const rowsLength = Math.ceil(randomQuestions.length / columns);
  const rowKeys = Array.from(Array(rowsLength).keys());
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          marginTop: 40,
          maxWidth: 830,
        }}
      >
        {rowKeys.map((n) => {
          const row =
            columns === 1
              ? [randomQuestions[n]]
              : [randomQuestions[n * 2], randomQuestions[n * 2 + 1]];
          return (
            <div key={n} style={{ display: "flex", flexDirection: "row" }}>
              {row.map((q, n) => {
                return (
                  <div
                    key={n}
                    style={{
                      flex: 1,
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                      color: "#000000",
                      borderRadius: 10,
                      padding: 10,
                      margin: 5,
                      minHeight: 40,
                      fontFamily: "Inter",
                      fontSize: 17,
                      fontStyle: "normal",
                      //fontWeight: 600,
                      visibility: q ? "visible" : "hidden",
                    }}
                    onClick={() => {
                      if (q) {
                        onClick(q);
                      }
                    }}
                  >
                    {q}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default QuestionsGroup;
