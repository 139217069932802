import { FC, useEffect, useState } from "react";
import { DbPodcast } from "../../models/podcast.db";

import { defaultColor, useThemeContext } from "../../context/ThemeContext";
import { service } from "../../services/firebase";
import useCustomerSession from "../../hooks/useCustomerSession";

type EditProps = {
  podcast: DbPodcast;
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const Subscribe: FC<EditProps> = ({ podcast }) => {
  const theme = podcast.theme || {};
  const themeBackgroundColor = theme.backgroundColor || defaultColor;
  const [backgroundColor, setBackgroundColor] =
    useState<string>(themeBackgroundColor);

  const contextTheme = useThemeContext();
  useEffect(() => {
    contextTheme.setColor(backgroundColor);
  }, [backgroundColor]);

  const { session } = useCustomerSession(service, () => {});

  if (!session) {
    return (
      <div
        style={{
          padding: 30,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        LOADING
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        width: "100%",
        height: "100%",
      }}
    >
      <stripe-pricing-table
        pricing-table-id="prctbl_1P0jhDRokl39M8fJ5Z7ipkZR"
        publishable-key="pk_test_51OwTrvRokl39M8fJND59Y4hmLI1kUw5IFmTsQE1gJBOtQlLW1mwAtqwoYltYQ8CVonRFrEU3vz6Z4TKRnsCS6sVd00n0A9OyUo"
        customer-session-client-secret={session.clientSecret}
      ></stripe-pricing-table>
    </div>
  );
};

export default Subscribe;
