import { FC, useEffect, useRef, useState } from "react";
import { Pagination } from "../Pagination";
import SourcePlayer, { AudioInfo } from "../SourcePlayer/SourcePlayer";
import { EventEmitter } from "stream";

interface PropTypes {
  audioInfos: Array<AudioInfo>;
  emitter: EventEmitter;
}

const Sources: FC<PropTypes> = ({ audioInfos, emitter }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollPos, setScrollPos] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);

  const part = scrollWidth / (audioInfos.length || 1);
  const pos = Math.min(
    Math.floor(scrollPos / part),
    audioInfos.length > 0 ? audioInfos.length - 1 : 0
  );

  useEffect(() => {
    const onNextAudio = (num: number) => {
      const nextAi = audioInfos[num - 1];
      if (nextAi) {
        emitter.emit("playStart", {
          playId: nextAi.id,
          startTime: -1,
        });
      }
    };
    emitter.addListener("onNextAudio", onNextAudio);

    return () => {
      emitter.removeListener("onNextAudio", onNextAudio);
    };
  }, [audioInfos, emitter]);

  useEffect(() => {
    setScrollPos(scrollRef.current?.scrollLeft ?? 0);
    setScrollWidth(
      (scrollRef.current?.scrollWidth ?? 0) -
        (scrollRef.current?.clientWidth ?? 0)
    );
  }, []);

  return audioInfos.length ? (
    <div className="sources_container">
      <div
        ref={scrollRef}
        className="sources"
        onScroll={() => {
          setScrollPos(scrollRef.current?.scrollLeft ?? 0);
          setScrollWidth(
            (scrollRef.current?.scrollWidth ?? 0) -
              (scrollRef.current?.clientWidth ?? 0)
          );
        }}
      >
        {audioInfos.map((ai) => (
          <SourcePlayer
            key={`${ai.num}-${ai.title}`}
            audioInfo={ai}
            emitter={emitter}
          />
        ))}
      </div>
      <Pagination items={audioInfos.length} active={pos} />
    </div>
  ) : null;
};
export default Sources;
