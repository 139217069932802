import { useEffect, useState } from "react";
import { FirebaseService } from "../services/firebase";
import { podBotServiceUrl } from "../constants";
import { DbAudio } from "../models/audio.db";
import { DbPodcast } from "../models/podcast.db";
import { useThemeContext } from "../context/ThemeContext";

export default function useAdminPodcast(
  podcastId: string | null | undefined,
  service: FirebaseService,
  setErrorText: React.Dispatch<React.SetStateAction<string>>
) {
  const [podcast, setPodcast] = useState<DbPodcast | null>(null);
  const [audios, setAudios] = useState<DbAudio[] | null>(null);
  const theme = useThemeContext();

  useEffect(() => {
    const getAudios = async () => {
      const token = await service.getToken();
      const response = await fetch(`${podBotServiceUrl}/api/admin/getAudios`, {
        method: "POST",
        body: JSON.stringify({ id: podcastId }),
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (data.status === "OK") {
        const mewAudios = data.audios;
        if (mewAudios) {
          setAudios(mewAudios);
        }
      }
    };
    const query = async () => {
      if (!podcastId) return;
      const token = await service.getToken();
      const response = await fetch(
        `${podBotServiceUrl}/api/admin/getPodcastById`,
        {
          method: "POST",
          body: JSON.stringify({ id: podcastId }),
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (data.status === "OK") {
        const newPodcast = data.podcast as DbPodcast;
        if (newPodcast) {
          setPodcast(newPodcast);
        }
      }
    };
    query();
    getAudios();
  }, [podcastId, service]);

  useEffect(() => {
    if (podcast) {
      const backgroundColor = podcast.theme.backgroundColor;
      theme.setColor(backgroundColor);
    }
  }, [podcast]);

  const uploadLogo = async (pic: any): Promise<string | null> => {
    if (!podcastId) {
      return null;
    }
    const token = await service.getToken();
    var picData = new FormData();
    picData.append("file", pic);
    picData.append("id", podcastId);

    const response = await fetch(`${podBotServiceUrl}/api/admin/uploadLogo`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: picData,
    });

    const data = await response.json();
    if (data.status === "OK") {
      return data.signedUrl;
    }
    return "";
  };

  const updatePodcast = async (newData: DbPodcast) => {
    const token = await service.getToken();
    const response = await fetch(
      `${podBotServiceUrl}/api/admin/updatetPodcast/`,
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
      }
    );

    const data = await response.json();
    if (data.status === "OK") {
      const newPodcast = data.podcast;
      if (newPodcast) {
        setPodcast(newPodcast);
      }
    }
  };

  return {
    podcast,
    audios,
    updatePodcast,
    uploadLogo,
  };
}
