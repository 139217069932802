import { FC } from "react";
import styles from "./Login.module.css";
import { iconsPrefix } from "../../constants";

interface PropTypes {
  text: string;
  onTextChanged: (text: string) => void;
  onSearchClicked: () => void;
  sent: boolean;
}

export const Login: FC<PropTypes> = ({
  text,
  onTextChanged,
  onSearchClicked,
  sent,
}) => {
  return (
    <div className={styles.verify}>
      <div className={styles.greeting}>
        {!sent
          ? "Hello! Before you get started with the PodBot, we need to verify your email:"
          : "Thank you! Check your inbox for an email verification link"}
      </div>
      {!sent ? (
        <div className={styles.verifyMail}>
          <input
            className={styles.typeMail}
            type="text"
            placeholder="Email"
            onChange={(event) => {
              onTextChanged(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onSearchClicked();
              }
            }}
          />
          <div
            className={styles.enterMail}
            onClick={() => {
              onSearchClicked();
            }}
          >
            <img src={`${iconsPrefix}icons/arrow-right.png`} alt="enterMail" />
          </div>
        </div>
      ) : (
        <div className={styles.verifyMailDone}>
          <img
            src={`${iconsPrefix}icons/verify_done.png`}
            alt="done"
            onClick={() => {}}
          />
        </div>
      )}
      <div className={styles.seeRules}>
        <div className={styles.seePrivacyPolicy}>
          See our{" "}
          <a className={styles.privacyPolicy} href="/privacy">
            privacy policy
          </a>
        </div>
        <div className={styles.seePrivacyPolicy}>
          and{" "}
          <a className={styles.privacyPolicy} href="/terms">
            terms of service
          </a>
        </div>
      </div>
    </div>
  );
};
