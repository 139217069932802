import { FC, useEffect, useState } from "react";
import { iconsPrefix } from "../../constants";
import styles from "./EditPodcast.module.css";
import EditNav from "./EditNav/EditNav";
import PodcastImg from "./PodcastImg/PodcastImg";
import TextInput from "../TextInput/TextInput";
import { useNavigate } from "react-router-dom";
import Podcast from "../Podcast";
import { DbPodcast } from "../../models/podcast.db";
import { DbAudio } from "../../models/audio.db";
import { useFilePicker } from "use-file-picker";
import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator,
  // ImageDimensionsValidator,
} from "use-file-picker/validators";

import { ColorPicker } from "../ColorPicker/ColorPicker";
import useWindowSize from "../../hooks/useWindowSize";
import { defaultColor, useThemeContext } from "../../context/ThemeContext";
import { mapToArray } from "../../utils";

const validateEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};

type EditProps = {
  podcast: DbPodcast;
  audios: DbAudio[];
  uploadLogo: (pic: any) => Promise<string | null>;
  onUpdatePodcast: (data: any) => Promise<void>;
};

export const fontsList = [
  "Arial",
  "Times New Roman",
  "Calibri",
  "Helvetica",
  "Futura",
  "Inter",
];

const EditPodcast: FC<EditProps> = ({
  podcast,
  onUpdatePodcast,
  uploadLogo,
  audios,
}) => {
  const navigate = useNavigate();
  const podcastName = podcast?.name ?? "";
  const podcastPrompt = podcast?.prompt ?? "";
  const podcastPublic: boolean = podcast?.public ?? false;
  const podcastAdmins: string = (podcast?.admins ?? []).join(", ");
  const podcastQuestions: string[] = podcast.questions ?? [];
  const podcastImageUrl: string = podcast.imageUrl;
  const theme = podcast.theme || {};
  //const themeFont: string = theme.fontFamily || fonts[0];
  //const themeFontSize: number = theme.fontSize || 14;
  const themeBackgroundColor = theme.backgroundColor || defaultColor;
  const [name, setName] = useState<string>(podcastName);
  //const [fontSize, setFontSize] = useState<number>(themeFontSize);
  //const [currentFont, setCurrentFont] = useState(themeFont);
  const [prompt, setPrompt] = useState(podcastPrompt);
  const [pPublic, setPublic] = useState<boolean>(podcastPublic);
  const [admins, setAdmins] = useState<string>(podcastAdmins);
  const [viewDraft, setViewDraft] = useState<boolean>(false);
  const [viewAudios, setViewAudios] = useState<boolean>(false);
  const [questions, setQuestions] = useState<string[]>(podcastQuestions);
  const [imageUrl, setImageUrl] = useState<string>(podcastImageUrl);
  const [backgroundColor, setBackgroundColor] =
    useState<string>(themeBackgroundColor);
  const [imageLoading, setImageLoading] = useState<boolean>(false);

  const { openFilePicker /*filesContent, loading, errors */ } = useFilePicker({
    readAs: "ArrayBuffer",
    accept: "image/*",
    multiple: false,
    onFilesSuccessfullySelected: async ({ plainFiles, filesContent }) => {
      try {
        setImageLoading(true);
        // this callback is always called, even if there are errors
        // console.log("onFilesSelected", plainFiles, filesContent);
        const logoUrl = await uploadLogo(plainFiles[0]);
        if (logoUrl) {
          setImageUrl(logoUrl);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setImageLoading(false);
      }
    },
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(["jpg", "png"]),
      new FileSizeValidator({ maxFileSize: 50 * 1024 * 1024 /* 50 MB */ }),
      /*
      new ImageDimensionsValidator({
        maxHeight: 900, // in pixels
        maxWidth: 1600,
        minHeight: 600,
        minWidth: 768,
      }),
      */
    ],
  });
  const windowSize = useWindowSize();
  const contextTheme = useThemeContext();
  useEffect(() => {
    contextTheme.setColor(backgroundColor);
  }, [backgroundColor]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: backgroundColor,
      }}
    >
      <div
        className={styles.edit_podcast}
        style={{
          width: viewDraft || viewAudios ? "50%" : "100%",
          height: windowSize.height - 50,
          overflowY: "scroll",
        }}
      >
        <div className={styles.main_container}>
          <EditNav
            podcastId={podcast.id}
            picURL={`${iconsPrefix}icons/podcast_back.svg`}
            isPreview={viewDraft}
            isAudios={viewAudios}
            onAudiosViewClick={() => {
              setViewDraft(false);
              setViewAudios(!viewAudios);
            }}
            onPreviewClick={() => {
              setViewDraft(!viewDraft);
              setViewAudios(false);
            }}
          />
          <PodcastImg
            loading={imageLoading}
            picURL={imageUrl}
            onClick={() => {
              //alert("Coming soon!");
              openFilePicker();
            }}
          />
          <div className={styles.input_title}>
            Podcast url: {podcast.podcastUrl}
          </div>
          <div className={styles.line}></div>
          <div
            className={styles.input_title}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>STATS:</div>
            <div className={styles.line}></div>
            <div>
              Completed:{audios.filter((a) => a.state === "EMBED_OK").length} of{" "}
              {audios.length}
            </div>
            <div>
              Audio processing:
              {
                audios.filter(
                  (a) =>
                    a.state === "AUDIO_PROCESSING" || a.state === "AUDIO_WAIT"
                ).length
              }
            </div>
            <div style={{ paddingLeft: 20 }}>
              queue:
              {audios.filter((a) => a.state === "AUDIO_WAIT").length}
            </div>
            <div style={{ paddingLeft: 20 }}>
              processing:
              {audios.filter((a) => a.state === "AUDIO_PROCESSING").length}
            </div>
            <div>
              Transcribing:
              {
                audios.filter(
                  (a) =>
                    a.state === "TRANSCRIBE_PROCESSING" ||
                    a.state === "TRANSCRIBE_WAIT"
                ).length
              }
            </div>
            <div style={{ paddingLeft: 20 }}>
              queue:
              {audios.filter((a) => a.state === "TRANSCRIBE_WAIT").length}
            </div>
            <div style={{ paddingLeft: 20 }}>
              processing:
              {audios.filter((a) => a.state === "TRANSCRIBE_PROCESSING").length}
            </div>
            <div>
              TextProcessing:
              {
                audios.filter(
                  (a) =>
                    a.state === "TEXT_PROCESSING" || a.state === "TEXT_WAIT"
                ).length
              }
            </div>
            <div style={{ paddingLeft: 20 }}>
              queue:
              {audios.filter((a) => a.state === "TEXT_WAIT").length}
            </div>
            <div style={{ paddingLeft: 20 }}>
              processing:
              {audios.filter((a) => a.state === "TEXT_PROCESSING").length}
            </div>
            <div>
              Embedding:
              {
                audios.filter(
                  (a) =>
                    a.state === "EMBED_PROCESSING" || a.state === "EMBED_WAIT"
                ).length
              }
            </div>
            <div style={{ paddingLeft: 20 }}>
              queue:
              {audios.filter((a) => a.state === "EMBED_WAIT").length}
            </div>
            <div style={{ paddingLeft: 20 }}>
              processing:
              {audios.filter((a) => a.state === "EMBED_PROCESSING").length}
            </div>
            <div>
              Errors:
              {
                audios.filter(
                  (a) =>
                    a.state === "AUDIO_ERROR" ||
                    a.state === "ERROR" ||
                    a.state === "EMBED_ERROR" ||
                    a.state === "TRANSCRIBE_ERROR" ||
                    a.state === "TEXT_ERROR"
                ).length
              }
            </div>
            <div style={{ paddingLeft: 20 }}>
              audio processing error:
              {audios.filter((a) => a.state === "AUDIO_ERROR").length}
            </div>
            <div style={{ paddingLeft: 20 }}>
              transcribe error:
              {audios.filter((a) => a.state === "TRANSCRIBE_ERROR").length}
            </div>
            <div style={{ paddingLeft: 20 }}>
              embed error:
              {audios.filter((a) => a.state === "EMBED_ERROR").length}
            </div>
            <div style={{ paddingLeft: 20 }}>
              other errors:
              {audios.filter((a) => a.state === "ERROR").length}
            </div>
          </div>
          <div className={styles.line}></div>
          <div className={styles.input_title}>Podcast name</div>
          <TextInput
            text={name}
            placeholder={"Add Podcast name"}
            onTextChanged={setName}
          />
          {/*
          <div className={styles.line}></div>
          <TextSize
            size={fontSize}
            onDecrease={() => {
              const newFontSize = fontSize <= 14 ? 14 : fontSize - 1;
              setFontSize(newFontSize);
            }}
            onIncrease={() => {
              const newFontSize = fontSize >= 30 ? 30 : fontSize + 1;
              setFontSize(newFontSize);
            }}
          />
          <TextFont
            fonts={fonts}
            current={currentFont}
            onSelect={setCurrentFont}
          />
            */}

          <div className={styles.line}></div>
          <div className={styles.input_title}>Podcast Colors</div>
          <ColorPicker
            title="Background"
            color={backgroundColor}
            onChangeColor={setBackgroundColor}
          />

          <div className={styles.line}></div>
          <div className={styles.input_title}>AI Prompt</div>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.currentTarget.value)}
            placeholder="AI Prompt"
            name="prompt"
            cols={40}
            rows={5}
            className={styles.input_text}
          />
          <div style={{ height: 20 }}></div>
          <div className={styles.line}></div>
          <div className={styles.input_title}>Admins</div>
          <TextInput
            text={admins}
            placeholder={"Add Podcast name"}
            onTextChanged={setAdmins}
          />
          <div style={{ width: 200, paddingTop: 20 }}>
            <div
              className={styles.save_btn}
              onClick={async () => {
                setPublic(!pPublic);
              }}
            >
              State: {pPublic === true ? "PUBLIC" : "CLOSED"}
            </div>
          </div>
          <div className={styles.line}></div>
          <div className={styles.input_title}>Demo questions</div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {questions.map((q, i) => {
              return (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <div style={{ flex: 1, paddingRight: 20 }}>
                    <TextInput
                      text={q}
                      placeholder={"Write your question"}
                      onTextChanged={(text) => {
                        const newQuestions = [...questions];
                        newQuestions[i] = text;
                        setQuestions(newQuestions);
                      }}
                    />
                  </div>
                  <div style={{ width: 80 }}>
                    <div
                      className={styles.save_btn}
                      onClick={async () => {
                        const newQuestios = questions.filter((q, k) => i !== k);
                        setQuestions(newQuestios);
                      }}
                    >
                      delete
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ width: 200, paddingTop: 20 }}>
            <div
              className={styles.save_btn}
              onClick={async () => {
                setQuestions([...questions, ""]);
              }}
            >
              Add question
            </div>
          </div>
          <div className={styles.line}></div>
          <div style={{ height: 50 }}></div>
          <div
            className={styles.save_btn}
            onClick={async () => {
              const newAdmins = admins
                .split("")
                .filter((l) => l.trim().toLowerCase())
                .join("")
                .split(",")
                .filter((s) => s.trim())
                .filter(validateEmail);
              const uniqSet = new Set(newAdmins);
              const finalAdmins = Array.from(uniqSet);

              await onUpdatePodcast({
                id: podcast.id,
                name: name,
                prompt: prompt,
                public: pPublic,
                admins: finalAdmins,
                questions: questions,
                imageUrl: imageUrl,
                theme: {
                  //fontFamily: currentFont || "",
                  //fontSize: fontSize || 14,
                  backgroundColor: backgroundColor,
                },
              } as Partial<DbPodcast>);
              navigate(-1);
            }}
          >
            Save
          </div>
          <div style={{ height: 30 }}></div>
        </div>
      </div>
      {viewDraft ? (
        <div
          style={{
            width: "50vw",
            height: windowSize.height - 50,
            overflowY: "scroll",
          }}
        >
          <Podcast
            id={podcast.id ?? ""}
            podcast={{
              ...podcast,
              ...{
                name: name,
                prompt: prompt,
                public: pPublic,
                questions: questions,
                imageUrl: imageUrl,
                theme: {
                  textColor: "",
                  fontFamily: "",
                  fontSize: 14,
                  backgroundColor: backgroundColor,
                },
              },
            }}
          />
        </div>
      ) : null}
      {viewAudios ? (
        <div
          style={{
            width: "50vw",
            scrollbarWidth: "thin",
            height: windowSize.height - 50,
            overflowY: "scroll",
          }}
        >
          <div style={{ padding: 10 }}>AUDIOS</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {audios.map((audio, i) => {
              const speakers = mapToArray(audio.speakers || {});
              return (
                <div
                  key={audio.id}
                  style={{
                    padding: 10,
                    borderStyle: "solid",
                    color:
                      audio.state === "AUDIO_ERROR" ||
                      audio.state === "ERROR" ||
                      audio.state === "EMBED_ERROR" ||
                      audio.state === "TRANSCRIBE_ERROR"
                        ? "red"
                        : "white",
                  }}
                >
                  <div>
                    {i + 1} of {audios.length}
                  </div>
                  <div>ID: {audio.id}</div>
                  <div>Title: {audio.title}</div>
                  <div>Description: {audio.description}</div>
                  <div>State: {audio.state}</div>
                  <div>
                    Added: {new Date(audio.created_at).toLocaleString()}
                  </div>
                  <div>
                    Updated: {new Date(audio.updated_at).toLocaleString()}
                  </div>
                  <div>
                    Speakers: ({speakers.length}) {speakers.join(", ")}
                  </div>
                  {audio.original_audio_url ? (
                    <div>
                      <a
                        href={audio.original_audio_url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        original audio
                      </a>
                    </div>
                  ) : null}
                  {audio.audio_url ? (
                    <div>
                      <a
                        href={audio.audio_url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        audio
                      </a>
                    </div>
                  ) : null}
                  {audio.json_url ? (
                    <div>
                      <a href={audio.json_url} rel="noreferrer" target="_blank">
                        json
                      </a>
                    </div>
                  ) : null}
                  {audio.text_url ? (
                    <div>
                      <a href={audio.text_url} rel="noreferrer" target="_blank">
                        text
                      </a>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EditPodcast;
