import { FC } from "react";
import { iconsPrefix } from "../../constants";
import styles from "./PodcastItem.module.css";

interface PropTypes {
  isAdmin: boolean;
  picURL: string;
  text: string;
  posted: boolean;
  onClicked: () => void;
  onDelete: () => void;
}

const PodcastItem: FC<PropTypes> = ({
  picURL,
  isAdmin,
  text,
  posted,
  onClicked,
  onDelete,
}) => {
  return (
    <div
      className={styles.podcast}
      style={{ opacity: posted ? 1 : 0.5 }}
      onClick={onClicked}
    >
      <div className={styles.podcast_item}>
        <div className={styles.podcast_img}>
          <img src={`${picURL}`} width={60} height={60} alt="Album_cover" />
        </div>
        <div className={styles.podcast_name}>{`${text}`}</div>
      </div>
      {isAdmin ? (
        <div
          className={styles.podcast_delete}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <img src={`${iconsPrefix}icons/Delete.svg`} alt="del" />
        </div>
      ) : null}
    </div>
  );
};
export default PodcastItem;
