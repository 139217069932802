import { FC, useState } from "react";
import { SketchPicker } from "react-color";
import { iconsPrefix } from "../../constants";

type PropTypes = {
  title: string;
  color: string;
  onChangeColor: (val: string) => void;
};

type Coord = {
  x: number;
  y: number;
};

export const ColorPicker: FC<PropTypes> = ({ title, color, onChangeColor }) => {
  const [displayCoord, setDisplayCoord] = useState<Coord | null>(null);

  const handleClick: React.MouseEventHandler<HTMLImageElement> = (e) => {
    setDisplayCoord(displayCoord ? null : { x: e.pageX, y: e.pageY });
  };

  const handleClose = () => {
    setDisplayCoord(null);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <img
          style={{ width: 40, cursor: "pointer" }}
          src={`${iconsPrefix}icons/colorPicker.png`}
          alt="picker"
          onClick={handleClick}
        />
        <div>{title}</div>
      </div>

      {displayCoord ? (
        <div
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: "fixed",
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={handleClose}
          />
          <div
            style={{
              position: "fixed",
              top: displayCoord.y,
              left: displayCoord.x,
            }}
          >
            <SketchPicker
              color={color}
              presetColors={[
                { color: "#6f29f1", title: "default" },
                // { color: "#FFFFFF", title: "white" },
                { color: "#EF4444", title: "red" },
                { color: "#F97316", title: "orange" },
                { color: "#FACC15", title: "yellow" },
                { color: "#4ADE80", title: "greeen" },
                { color: "#2DD4BF", title: "teal" },
                { color: "#3B82F6", title: "blue" },
                { color: "#6467F2", title: "indigo" },
                //
                { color: "#EC4899", title: "pink" },
                { color: "#F43F5E", title: "rose" },
                { color: "#D946EF", title: "fuchisia" },
                { color: "#8B5CF6", title: "violet" },
                { color: "#0EA5E9", title: "lightBlue" },
                { color: "#10B981", title: "emerald" },
                { color: "#84CC16", title: "lime" },
              ]}
              onChangeComplete={(c) => {
                onChangeColor(c.hex);
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
