import { useEffect, useState } from "react";
import { FirebaseService } from "../services/firebase";
import { podBotServiceUrl } from "../constants";

type CustomerSessionData = {
  status: string;
  clientSecret: string;
};

export default function useCustomerSession(
  service: FirebaseService,
  setErrorText: React.Dispatch<React.SetStateAction<string>>
) {
  const [session, setSession] = useState<CustomerSessionData | null>(null);

  useEffect(() => {
    const createCustomerSession = async () => {
      const token = await service.getToken();
      const response = await fetch(
        `${podBotServiceUrl}/api/payments/createCustomerSession`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (data.status === "OK") {
        setSession(data);
      }
    };
    createCustomerSession();
  }, [service]);

  return {
    session,
  };
}
