import { useEffect, useState } from "react";
import { FirebaseService } from "../services/firebase";
import { podBotServiceUrl } from "../constants";

type CustomerSubscriptionData = {
  status: string;
  subscribed: boolean;
};

export default function useCustomerSubscription(
  uid: string,
  podcastId: string,
  service: FirebaseService,
  setErrorText: React.Dispatch<React.SetStateAction<string>>
) {
  const [subscription, setSubscription] =
    useState<CustomerSubscriptionData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const checkCustomerSubscribed = async (uid: string, podcastId: string) => {
    if (!uid) return;
    if (!podcastId) return;
    const token = await service.getToken();
    const response = await fetch(
      `${podBotServiceUrl}/api/payments/checkCustomerSubscribed`,
      {
        method: "POST",
        body: JSON.stringify({
          podcastId: podcastId,
        }),
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    if (data.status === "OK") {
      setSubscription(data);
      setLoading(false);
    }
  };

  const reset = () => {
    checkCustomerSubscribed(uid, podcastId);
  };

  useEffect(() => {
    checkCustomerSubscribed(uid, podcastId);
  }, [service, podcastId, uid]);

  return {
    subscription,
    loading,
    reset,
  };
}
