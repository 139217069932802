import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
  onAuthStateChanged,
  getIdToken,
  User,
} from "firebase/auth";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { podBotServiceUrl } from "../constants";

export interface FirebaseService {
  logEvent: (name: string, params?: Object) => void;
  sendSigninLinkToEmail: (email: string) => Promise<void>;
  signInWithEmailLink: () => Promise<string | void>;
  signOut: () => Promise<void>;
  onAuthStateChanged: (fn: (user?: User) => void) => Promise<void>;
  incQuestionsAsked: (uid: string) => void;
  onQuestionsAsked: (
    uid: string,
    onChange: (num: number) => void
  ) => () => void;
  setUserProperties: (obj: { [key: string]: unknown }) => void;
  getToken: () => Promise<string | null>;
  getEmail: () => string | null | undefined;
  getUid: () => string | null | undefined;
}

const firebaseConfig = {
  apiKey: "AIzaSyDOCYGmeNmxVWdWbrg-VBXQ-AW2HFybrFo",
  authDomain: "planetmoneybot.com",
  projectId: "podbot-f6540",
  storageBucket: "podbot-f6540.appspot.com",
  messagingSenderId: "562807661986",
  appId: "1:562807661986:web:87d58a7850b1299253befb",
  measurementId: "G-D7S54ZEPCY",
};

/*
const firebaseConfig = {
  apiKey: "AIzaSyDDfAHW-5NtShgyckPFfbe4y0_ouQLbEAk",
  authDomain: "podbotproject.firebaseapp.com",
  projectId: "podbotproject",
  storageBucket: "podbotproject.appspot.com",
  messagingSenderId: "902517838930",
  appId: "1:902517838930:web:31732ae6973d8a3900bb8b",
  measurementId: "G-8LGTTQZVX9",
};
*/

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const getToken = async (): Promise<string | null> => {
  const user = auth.currentUser;
  if (!user) return null;
  return await getIdToken(user);
};

const getEmail = () => {
  return auth.currentUser?.email;
};

const getUid = () => {
  return auth.currentUser?.uid;
};

export const service: FirebaseService = {
  getUid: getUid,
  getEmail: getEmail,
  logEvent: (name: string, params?: Object) => {
    logEvent(analytics, name, params);
  },
  sendSigninLinkToEmail: async (email: string) => {
    await new Promise<void>(async (resolve, reject) => {
      const response = await fetch(
        `${podBotServiceUrl}/api/auth/generateSignInWithEmailLink`,
        {
          method: "POST",
          body: JSON.stringify({
            email: email,
            actionCodeSettings: {
              url: window.location.href,
              handleCodeInApp: true,
            },
          }),
          headers: {
            // authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.status === "OK") {
        resolve();
      } else {
        reject();
      }
    });
    /*
    await sendSignInLinkToEmail(auth, email, {
      url: window.location.href,
      handleCodeInApp: true,
    });
    */
    window.localStorage.setItem("emailForSignIn", email);

    //catch (error) {
    //const errorCode = error.code;
    //const errorMessage = error.message;
    //}
  },
  signInWithEmailLink: async () => {
    //window.location = "/";
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      if (email === null || email === "") return;
      console.log("SIGNING");
      // The client SDK will parse the code from the link for you.
      const cred = await signInWithEmailLink(auth, email, window.location.href);
      return cred.user?.uid;
      //window.localStorage.removeItem("emailForSignIn");
    } else {
      //console.log("WRONG LINK");
    }
  },
  onAuthStateChanged: async (fn: (user?: User) => void) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        setUserId(auth, uid);
        fn(user);
        // ...
      } else {
        // User is signed out
        // ...
        fn();
      }
    });
  },
  signOut: async () => {
    await signOut(auth);
  },
  setUserProperties: async (obj: { [key: string]: unknown }) => {
    setUserProperties(analytics, obj);
  },
  incQuestionsAsked: async (uid: string) => {
    /*
    const docRef = doc(firestore, "questions_asked", uid);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data() || {};
    const count = data["count"] || 0;
    await setDoc(docRef, { count: count + 1 });
    */
  },
  onQuestionsAsked: (uid: string, onChange: (num: number) => void) => {
    /*
    const docRef = doc(firestore, "questions_asked", uid);
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data() || {};
        const count = data["count"] || 0;
        onChange(count);
      } else {
        onChange(0);
      }
    });
    return unsubscribe;
    */
    return () => {};
  },
  getToken: getToken,
};

//@ts-ignore
window.signOut = service.signOut;
