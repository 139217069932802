import { FC, useEffect, useRef, useState } from "react";
import { PlayerRange } from "../PlayerRange";
import { EventEmitter } from "events";
import { TranscriptionItem, parseDeepgram, parseStart } from "../../utils";
import { TrText } from "../TrText";
import { iconsPrefix } from "../../constants";
export interface AudioInfo {
  id: string;
  num: number;
  title: string;
  audioUrl: string;
  transcriptionUrl: string;
  text: string;
  startTime: string;
  speakers?: { [key: string]: string };
  date: string;
}

export interface SourcePropTypes {
  audioInfo: AudioInfo;
  emitter: EventEmitter;
}

const SourcePlayer: FC<SourcePropTypes> = ({ audioInfo, emitter }) => {
  const { id, num, audioUrl, title, startTime, text, date, speakers } =
    audioInfo;
  const playerRef = useRef<HTMLAudioElement>(null);

  const start = parseStart(startTime);
  const [expanded, setExpanded] = useState(false);
  const [muted, setMuted] = useState(false);
  const [play, setPlay] = useState(false);
  const [time, setTime] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [volume, setVolume] = useState(1);
  const [items, setItems] = useState<Array<TranscriptionItem>>([]);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");

  const expandedClasses = ["expanded_view", expanded ? "show" : ""]
    .filter((c) => c)
    .join(" ");

  useEffect(() => {
    if (!audioUrl) return;
    if (!playerRef.current) return;
    playerRef.current.onloadedmetadata = function () {
      const _maxVal =
        Math.round((playerRef?.current?.duration || 0) * 100) / 100;
      const startValue = _maxVal < start ? 0 : start;
      if (playerRef?.current) {
        playerRef.current.currentTime = startValue;
      }
      setMaxValue(_maxVal);
      setTime(startValue);
    };

    playerRef.current.onended = function () {
      setPlay(false);
      setTime(0);
    };

    playerRef.current.onvolumechange = function () {
      setVolume(playerRef.current?.volume || 0);
    };

    const onPlayStart = (event: { playId: string; startTime: number }) => {
      const { playId, startTime } = event;
      if (!playerRef.current) return;
      if (playId === id) {
        if (startTime >= 0) playerRef.current.currentTime = startTime;
        setExpanded(true);
        setPlay(true);
        playerRef.current.play();
      } else {
        setExpanded(false);
        setPlay(false);
        playerRef.current.pause();
      }
    };
    emitter.addListener("playStart", onPlayStart);

    return () => {
      emitter.removeListener("playStart", onPlayStart);
    };
  }, [audioUrl, emitter, id, start]);

  useEffect(() => {
    if (!expanded || items.length || !audioInfo.transcriptionUrl) {
      return;
    }
    const fn = async () => {
      console.log(audioInfo.transcriptionUrl);
      try {
        setLoading(true);
        setErrorText("");
        const jsonDataResponse = await fetch(audioInfo.transcriptionUrl);
        const json = await jsonDataResponse.json();
        const items = parseDeepgram(json);

        if (playerRef.current) {
          const track = playerRef.current.addTextTrack("metadata");

          items.forEach((c) => {
            track.addCue(c.vttCue);
          });
        }

        setItems(items);
      } catch (e) {
        console.error(e);
        setErrorText("CAN'T LOAD TRANSCRIPTION!");
      } finally {
        setLoading(false);
      }
    };
    fn();
  }, [audioInfo.transcriptionUrl, playerRef, expanded, items.length]);

  useEffect(() => {
    if (!playerRef.current) return;
    playerRef.current.volume = volume;
  }, [volume]);

  useEffect(() => {
    if (play) {
      const interval = setInterval(() => {
        if (!playerRef.current) return;
        setTime(Math.round(playerRef.current.currentTime * 100) / 100);
      }, 300);
      return () => {
        clearInterval(interval);
      };
    }
  }, [play]);

  const startPlayAndExpand = () => {
    setExpanded(true);
    startPlay();
  };

  const stopPlayAndUnexpand = () => {
    setExpanded(false);
    stopPlay();
  };

  const startPlay = () => {
    if (!playerRef.current) return;
    emitter.emit("onAudioPlay", id);
    setPlay(true);
    playerRef.current.play();
  };

  const backwardForward = (direction: number) => {
    if (!playerRef.current) return;
    const t = playerRef.current.currentTime;
    setPlayerTime(t + direction);
  };

  const setPlayerTime = (pos: number) => {
    if (!playerRef.current) return;
    pos = Math.round(pos * 100) / 100;
    playerRef.current.currentTime = pos;
    setTime(pos);
  };

  const stopPlay = () => {
    if (!playerRef.current) return;
    setPlay(false);
    playerRef.current.pause();
  };

  const startStop = () => {
    play ? stopPlay() : startPlay();
  };

  const startStopWithExpand = () => {
    play ? stopPlayAndUnexpand() : startPlayAndExpand();
  };

  const mute = () => {
    if (!playerRef.current) return;
    playerRef.current.muted = true;
    setMuted(playerRef.current.muted);
  };

  const unMute = () => {
    if (!playerRef.current) return;
    playerRef.current.muted = false;
    setMuted(playerRef.current.muted);
  };

  const muteUnmute = () => {
    muted ? unMute() : mute();
  };

  return (
    <div className="source">
      {audioUrl ? (
        <audio ref={playerRef} style={{ width: "100%" }}>
          {audioUrl ? <source src={audioUrl} /> : null}
        </audio>
      ) : null}
      <div
        onClick={(e) => {
          e.stopPropagation();
          startPlayAndExpand();
        }}
      >
        <div className="source_title">
          {/* <div className="source_num">{`Source ${num}`}</div> */}
          <div className="source_num">{`${date}`}</div>
          <div
            className="source_fullscreen"
            onClick={(e) => {
              e.stopPropagation();
              startPlayAndExpand();
            }}
          >
            <img src={`${iconsPrefix}icons/fullscreen.svg`} alt="fullscreen" />
          </div>
        </div>
        <div className="source_descr">{title}</div>

        {audioUrl ? (
          <div className="source_player">
            <img
              width={30}
              height={30}
              className="play"
              src={`${iconsPrefix}icons/${play ? "pause" : "play"}.svg`}
              alt="play"
              onClick={(e) => {
                e.stopPropagation();
                startStopWithExpand();
              }}
            />
            <PlayerRange
              maxValue={maxValue || 1}
              minValue={0}
              step={0.01}
              value={time}
              onChange={setPlayerTime}
            />
            <img
              className="sound"
              src={`${iconsPrefix}icons/sound_${muted ? "on" : "off"}.svg`}
              alt="sound"
              onClick={(e) => {
                e.stopPropagation();
                muteUnmute();
              }}
            />
          </div>
        ) : (
          <div
            className="source_player"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              color: "black",
            }}
          >
            NO AUDIO
          </div>
        )}
      </div>
      <div className={expandedClasses}>
        <div className="view_block">
          <div className="exp_view_header">
            <div className="exp_view_title">{audioInfo.title}</div>
            <div
              className="source_basis"
              onClick={(e) => {
                e.stopPropagation();
                stopPlayAndUnexpand();
              }}
            >
              <img
                src={`${iconsPrefix}icons/minimize_window.svg`}
                alt="minimize window"
              />
            </div>
          </div>
          <div className="scroll_content">
            <div className="exp_view_source">
              {errorText ? (
                <div style={{ whiteSpace: "pre-line" }}>{errorText}</div>
              ) : loading ? (
                <div>...LOADING...</div>
              ) : items.length > 0 ? (
                <TrText
                  playId={id}
                  items={items}
                  eventEmitter={emitter}
                  speakers={speakers ?? {}}
                />
              ) : text ? (
                <div style={{ whiteSpace: "pre-line" }}>{text}</div>
              ) : (
                <div style={{ whiteSpace: "pre-line" }}>NO TEXT</div>
              )}
            </div>
          </div>
          {audioUrl ? (
            <div className="exp_footer">
              <div style={{ height: 31 }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingRight: 30,
                  paddingLeft: 30,
                }}
              >
                <PlayerRange
                  maxValue={maxValue || 1}
                  minValue={0}
                  step={0.01}
                  value={time}
                  onChange={setPlayerTime}
                />
              </div>

              <div className="exp_player">
                <div
                  className="exp_player_back"
                  onClick={() => {
                    backwardForward(-15);
                    //emitter.emit("onNextAudio", num - 1);
                  }}
                >
                  <img src={`${iconsPrefix}icons/back.svg`} alt="back" />
                </div>
                <div className="exp_player_play">
                  <img
                    src={`${iconsPrefix}icons/${play ? "pause" : "play"}1.svg`}
                    alt="play"
                    onClick={startStop}
                  />
                </div>
                <div
                  className="exp_player_forward"
                  onClick={() => {
                    backwardForward(15);
                    //emitter.emit("onNextAudio", num + 1);
                  }}
                >
                  <img src={`${iconsPrefix}icons/forward.svg`} alt="forward" />
                </div>
              </div>
            </div>
          ) : (
            <div
              className="exp_footer"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
              }}
            >
              <div>NO AUDIO</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default SourcePlayer;
