import { FC, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAdminPodcasts from "../../hooks/useAdminPodcasts";
import useAuth from "../../hooks/useAuth";
import { service } from "../../services/firebase";
import { iconsPrefix } from "../../constants";
import PodcastItem from "../PodcastItem/PodcastItem";
import usePodcasts from "../../hooks/usePodcasts";
import { useThemeContext } from "../../context/ThemeContext";

export const UserPodcasts: FC = () => {
  const theme = useThemeContext();
  const col = theme.getColor();
  const location = useLocation();
  useEffect(() => {
    theme.getColor();
  }, [location, col, theme]);
  const navigate = useNavigate();
  const { podcasts } = usePodcasts(service);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: 103,
        gap: 26,
      }}
    >
      <Link to={"/admin"}>
        <div
          style={{
            marginRight: 25,
            marginTop: 20,
            position: "absolute",
            right: 0,
            cursor: "pointer",
          }}
        >
          ADMIN LOGIN
        </div>
      </Link>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignSelf: "center",
          maxWidth: "918px",
        }}
      >
        <img
          src={`${iconsPrefix}icons/ALL_PODCASTS1.svg`}
          alt="YOUR_PODCASTS"
          style={{
            width: "100%",
            height: "auto",
            padding: 36,
          }}
        />
      </div>
      {podcasts.map((u: any, i: number) => {
        return (
          <div
            key={i}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: 22,
              paddingRight: 22,
            }}
          >
            <PodcastItem
              isAdmin={false}
              picURL={u.imageUrl}
              text={u.name}
              posted={u.public}
              onClicked={() => {
                navigate(u.id);
              }}
              onDelete={() => {}}
            />
          </div>
        );
      })}
    </div>
  );
};

export const AdminPodcasts: FC = () => {
  const navigate = useNavigate();
  const pmAuth = useAuth(service, () => {});
  const uid = pmAuth.uid;
  const { podcasts, createPodcast, deletePodcast } = useAdminPodcasts(
    uid || "",
    service
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: 103,
        gap: 26,
      }}
    >
      <div
        style={{
          display: "flex",
          //width: "100%",
          alignSelf: "center",
          //maxWidth: "918px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={`${iconsPrefix}icons/YOUR_PODCASTS1.svg`}
          alt="YOUR_PODCASTS"
          style={{
            alignSelf: "center",
            maxWidth: "80%",
            maxHeight: "80%",
            padding: 36,
          }}
        />
      </div>
      {podcasts.map((u: any, i: number) => {
        return (
          <div
            key={i}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: 22,
              paddingRight: 22,
            }}
          >
            <PodcastItem
              isAdmin={true}
              picURL={u.imageUrl}
              text={u.name}
              posted={u.public}
              onClicked={() => {
                navigate(u.id);
              }}
              onDelete={async () => {
                const promptId = prompt("Comfirm podcast ID to delete");
                if (!promptId) return;
                if (promptId === u.id) {
                  alert("OK, podcast will be deleted");
                  const status = await deletePodcast(u.id);
                  if (status === "OK") {
                    alert(`podcast ${u.id} deleted`);
                  }
                } else {
                  alert("Wrong podcast ID");
                }
              }}
            />
          </div>
        );
      })}
      <div
        onClick={async () => {
          /*
              alert(
                "Podcasts creation temporarily paused.\nPlease contact dima@sunaolab.com"
              );
          */
          const url = prompt("Enter podcast URL");
          if (!url) return;
          alert("OK, creating podcast: " + url);
          await createPodcast(url);
        }}
        style={{
          textAlign: "right",
          position: "fixed",
          bottom: "16px",
          right: "16px",
          cursor: "pointer",
        }}
      >
        <img src={`${iconsPrefix}icons/podcast_add.svg`} alt="add" />
      </div>
    </div>
  );
};
